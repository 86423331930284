/* App.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables globales */
:root {
  --primary-color: #f89d30;
  --button-default-color: #2c2f36; /* Couleur par défaut des boutons */
  --input-background-color: #2c2f36; /* Couleur de fond des champs input */
  --background-color: #100c1c; /* Nouvelle couleur de fond */
  --footer-background-color: #181c2c; /* Nouvelle couleur du footer */
}

/* Styles globaux */
body {
  background-color: var(--background-color); /* Applique la couleur de fond */
  color: white;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.content-wrap {
  flex: 1;
}

.footer {
  background-color: transparent;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
