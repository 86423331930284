/* index.css */

.view-container {
  display: flex;
  flex-direction: row; /* Side by side on larger screens */
  justify-content: space-between;
  padding: 20px;
}

.view-container-soon {
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  background-color: #100c1c; /* Couleur de fond si vous souhaitez un fond spécifique */
  color: white; /* Couleur du texte */
  font-size: 24px; /* Taille du texte */
  text-align: center; /* Centre le texte à l'intérieur de l'élément */
}

.tradingview-container,
.module-container {
  width: 100%;
}

@media (max-width: 768px) {
  .view-container {
    flex-direction: column; /* Stack on smaller screens */
  }

  .tradingview-container,
  .module-container {
    width: 100%;
    margin-bottom: 20px; /* Space between stacked elements */
  }
}
